<template>
	<div>
		<van-nav-bar
			title="註冊"
			left-arrow
			class="qjc-nav-bar"
			fixed
			@click-left="$router.back()"
		/>
		
		<div class="regist qjc-input-group">
			<h2 class="title qjc-c-dark qjc-texta-l">米因健康大數據體檢平台</h2>
			<div class="qjc-relative">
				<van-field
					placeholder="手機號"
					v-model="account"
					@input="accountType"
					@blur="accountReg"
				/>
				<div v-if="phoneSel" class='qjc-c-dark phone-sel qjc-absolute'>
					<div @click="phoneSel2 = !phoneSel2">
						+{{ area.active }}
						<van-icon name="arrow-down"/>
					</div>
					<ul v-if="phoneSel2" class="qjc-texta-l qjc-absolute">
						<li @click="area.active = area.mainland,phoneSel2 = false">中国(+86)</li>
						<li @click="area.active = area.HK,phoneSel2 = false">中国香港特别行政区(+852)</li>
					</ul>
				</div>
			</div>
			<van-field
				placeholder="驗證碼"
				v-model="code"
			>
				<van-button 
					round 
					slot="button" 
					class="get-code qjc-c-primary"
					:disabled="isSend"
					@click="getImgCode"
				>
					<span v-show="isSend">
						<van-count-down
							:time="2 * 60 * 1000"
							format="ss"
							ref="countDown"
							:auto-start="false"
							@finish="finish"
						/>s后重新获取
					</span>
					<span v-show="!isSend">發送驗證碼</span>
				</van-button>
			</van-field>
			<van-field
				placeholder="密碼"
				v-model="password"
				type="password"
			/>
			<div class="agreement qjc-texta-l">
				<van-checkbox v-model="isAgree" icon-size="0.24rem">
					我已閱讀和同意<span @click="toAgreement" class="qjc-c-primary">《米因健康大數據體檢平台用戶協議》</span>
				</van-checkbox>
			</div>
			<van-button 
				round
				plain
				@click="nextStep"
				class="regist-btn qjc-wid-100 qjc-c-primary qjc-fts-36 qjc-ftw-b"
			>註冊</van-button>
			<p class="qjc-texta-l">已有賬號？去<router-link to="/login" class="qjc-c-primary">登錄</router-link></p>
		</div>
		
		<!-- 图形验证码 -->
		<van-popup 
			v-model="imgCode.show" 
			class="img-code"
			:close-on-click-overlay="false"
		>
			<div class="qjc-input-group">
				<span @click="imgCode.show=false" class="close qjc-absolute"></span>
				<h4 class="qjc-fts-32 qjc-c-dark qjc-ftw-n">請輸入圖形驗證碼</h4>
				<van-field
					v-model="imgCode.code"
					type="number"
					placeholder="請輸入驗證碼"
				>
					<van-image
						slot="button"
						width="1.82rem"
						height="0.64rem"
						:src="imgCode.imgSrc"
						@click="getImgCode()"
					/>
				</van-field>
				<van-button @click="getCode()" class="img-code-btn qjc-fonts-32 qjc-bg-primary qjc-c-fff">確定</van-button>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, Button, CountDown, Checkbox, Toast, Icon, Popup, Image } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(Button)
	   .use(CountDown)
	   .use(Checkbox)
	   .use(Toast)
	   .use(Icon)
	   .use(Popup)
	   .use(Image);
	   
	import { isPhone, isHKPhone, isEmail, pwdReg } from '@/js/utils.js'
	import { getVerifyCode, accountExist, verifyCode, getImageCode } from "@/js/axios.js"
	
	export default{
		name: 'regist',
		data (){
			return {
				phoneSel: false,//手機號選區
				phoneSel2: false,
				area: {
					active: '852',//當前選中 默認為大陸
					mainland: '86',//大陸
					HK: '852'//香港
				},//號碼所屬區域  默認大陸 +86
				account: '',//手机号/邮箱
				code: '',//验证码
				password: '',//密码
				isSend: false,//是否发送验证码 默认为false 用来控制发送按钮内容切换
				isAgree: this.$route.params.isAgree?this.$route.params.isAgree:false,//是否阅读协议 默认false(如没有在协议选择是否同意,否则以选择选项为主)
				imgCode: {
					show: false,
					code: '',
					imgSrc: ''
				}//图形验证码
			}
		},
		mounted (){
			Object.assign(this.$data,JSON.parse(sessionStorage.getItem('registForm')));
			this.accountType(this.account);
		},
		methods: {
			getImgCode (){// 獲取圖形驗證碼 
				let account = this.account;
				if(this.accountReg()){
					this.imgCode.show = true;
					this.imgCode.code = '';
					getImageCode({
						phone: account
					}).then(res => {
						if(res.status == 1){
							this.imgCode.imgSrc = res.captcha;
						}else{
							Toast.fail(res.msg);
						}
					});
				}
			},
			getCode (){// 获取验证码
				if(this.imgCode.code.trim() == ''){
					Toast.fail('圖形驗證碼不能為空');
					return;
				}
				let account = this.account;
				this.saveForm();
				let data = {
					account: account,
					// type: (isPhone(account) || isHKPhone(account))?1:(isEmail(account)?90:''),//1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码,
					type: 1,
					loa: this.area.active == '852'?'00852':'86',
					captcha: this.imgCode.code
				};
				if(this.accountReg()){
					var that = this;
					getVerifyCode(data).then(function(res2){
						if(res2.status == 1){
							Toast.success(res2.msg);
							that.imgCode.show = false;
							that.isSend = true;
							that.$refs.countDown.start();
						}else if(res2.status == -1){
							Toast.fail(res2.msg);
							that.imgCode.imgSrc = res2.captcha;
						}else{
							Toast.fail(res2.msg);
						}
					});
				}
			},
			finish (){// 倒计时结束
				this.isSend = false;
				this.$refs.countDown.reset();// 重置
			},
			
			//第一步注册按钮 校验验证码 保存表单数据
			nextStep (){
				// 不為空驗證
				if(this.account.trim() == ''){
					Toast.fail('手機號不能為空');
				}else if(this.code.trim() == ''){
					Toast.fail('驗證碼不能為空');
				}else if(this.password.trim() == ''){
					Toast.fail('密碼不能為空');
				}else if(this.isAgree == false){
					Toast.fail('請閱讀和同意米因協議');
				}else if(this.password.indexOf(' ') != -1){
					Toast.fail('密碼不可包含空格');
				}else{
					//格式驗證
					if(isPhone(this.account) || isHKPhone(this.account)){
						if(pwdReg(this.password)){
							verifyCode({
								account: this.account,
								code: this.code,
								// type: (isPhone(this.account) || isHKPhone(this.account))?1:(isEmail(this.account)?90:'')//1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码
								type: 1,
							}).then( res => {
								if(res.status == 1){
									this.saveForm();
									this.$router.push("/regist/perfectInfo");
								}else{
									Toast.fail(res.msg);
								}
							})
						}else{
							Toast.fail('密碼請使用兩種以上字符');
						}
					}else{
						Toast.fail('手機號格式不正確');
					}
				}
			},
			
			toAgreement (){
				this.saveForm();
				this.$router.push('/regist/agreement');
			},//跳轉至協議
			
			saveForm (){
				var data = {
					account: this.account,
					code: this.code,
					password: this.password,
					isAgree: this.isAgree,
					loa: this.area.active == '852'?'00852':'86'
				}
				sessionStorage.setItem('registForm',JSON.stringify(data));
			},//保存表單數據 以免切換組件數據丟失
			
			// 驗證
			accountType (val){
				if(/^[0-9]+$/.test(val)){
					this.phoneSel = true;
					
					if(isPhone(val)){
						this.area.active = this.area.mainland;
					}else if(isHKPhone(val)){
						this.area.active = this.area.HK;
					}
				}else{
					this.phoneSel = false;
				}
			},//賬號類型檢測
			
			accountReg (){
				if(this.account.trim() == ''){
					Toast.fail('手機號不能為空');
					return false;
				}else if(isPhone(this.account) || isHKPhone(this.account)){
					return true
				}else{
					Toast.fail('請填寫正確格式的手機號');
					return false;
				}
			}//賬號驗證
		}
	}
</script>

<style scoped>
	.van-checkbox >>> .van-checkbox__label{
		color: #A1A7B2;
		font-weight: 500;
	}
	.img-code >>> .van-field__button{
		height: 0.64rem;
	}
</style>
<style lang="scss" scoped>
	.regist{
		margin-top: 0.88rem;
		color: #A1A7B2;
		padding: 0.68rem 0.6rem;
		
		.title{
			font-size: 0.44rem;
			margin-bottom: 0.5rem;
		}
		.van-field{
			padding: 0.48rem 0 0.28rem 0;
		}
		.phone-sel{
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			font-size: 0.28rem;
			z-index: 999;
			
			ul{
				width: 3.7rem;
				background-color: #fff;
				padding: 0 0.12rem;
				line-height: 0.56rem;
				border: 0.01rem solid #DCDEE1;
				border-radius: 0.08rem;
				top: 100%;
				right: 0;
				
				li:not(:last-child){
					border-bottom: 0.01rem solid #DCDEE1;
				}
			}
		}
		.get-code{
			height: 0.48rem;
			line-height: 0.48rem;
			border: 1px solid #627AF5;
			
			.van-count-down, .van-divider{
				display: inline;
				color: #6681FA;
				line-height: 0.48rem;
			}
		}
		.van-checkbox{
			font-size: 0.2rem;
			margin: 0.22rem 0;
		}
		.regist-btn{
			height: 0.88rem;
			line-height: 0.88rem;
			border-color: #627AF5;
			margin: 0.32rem 0;
		}
	}
	.img-code{
		width: 6rem;
		height: 3.49rem;
		padding: 0.4rem 0.5rem;
		border-radius: 0.12rem;
		margin-top: -0.6rem;
		overflow: visible;
		
		.close{
			width: 0.44rem;
			height: 0.44rem;
			line-height: 0.44rem;
			top: -0.6rem;
			right: 0;
			font-size: 0.16rem;
			color: #fff;
			background: url(../../assets/images/close.png) no-repeat center center;
			background-size: 0.16rem 0.16rem;
			border-radius: 50%;
			border: 0.02rem solid #fff;
		}
		h4{
			line-height: 0.46rem;
			margin-bottom: 0.48rem;
		}
		.van-field{
			position: relative;
			width: 100%;
			height: 0.64rem;
			padding: 0;
			border-bottom: 1px solid #DCDEE5;
			z-index: 10;
			
			.van-image{
				z-index: 9999;
			}
		}
		.img-code-btn{
			width: 100%;
			height: 0.78rem;
			line-height: 0.78rem;
			margin-top: 0.33rem;
			border-radius: 0.04rem;
			font-weight: 400;
		}
	}
</style>
